import { useFlags } from 'launchdarkly-react-client-sdk';

import { removeSupportFromUrl } from '../../utils/url';
import env from '../../utils/env';
import { useEffect } from 'react';

const Content = () => { 
  

  const pathName = removeSupportFromUrl(window.location.pathname);
  const {
    dixaSupport: dixaSupportFF,
  } = useFlags();
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data && event.data.path) {
        window.history.pushState({}, '', event.data.path);
      }
    };
  
    window.addEventListener('message', handleMessage);
  
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  
  return (
    <>
      {
        dixaSupportFF ?
          <iframe src={`${env.elevioURL}${pathName}`} title="content" className="dixa-support" id="dixa-support" /> :
          <div id="gladly-help-center" data-testid="gladly-help-center" />
      }
    </>
  )
}

export default Content;
