import ReactDOM from 'react-dom';
import App from './App';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import env from './utils/env';
import cookies from './utils/cookie';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: env.ld || '',
    context: {
      kind: 'user',
      key: cookies.get('ajs_user_id') || 'anon',
    },
    options: {
      useReport: true,
    },
  });
  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root'),
  );
})();
