import { GlobalFooter } from '@nexus/fff-global-footer';
import { GlobalNav } from '@nexus/fff-global-nav';

import Content from './components/content';

import './assets/css/reset.css';
import './assets/css/support.css';


function App() {
  return (
    <div>
      <GlobalNav />
        <Content />
      <GlobalFooter />
    </div>
  );
}

export default App;
