const cookies = {
  set: (key: string, value: string, exdays: number): void => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    const v = encodeURIComponent(value);
    document.cookie = key + "=" + v + ";" + expires + ";path=/";
  },

  get: (key: string): string => {
    const name = key + "=";
    const decodedCookie = decodeURIComponent(document.cookie);

    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  delete: (key: string): void => {
    const d = new Date();
    d.setTime(d.getTime() + (-10 * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = `${key}=; ${expires}; path=/`;
  }
};

export default cookies;

